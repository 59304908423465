
<script setup lang="ts">
import { storeToRefs } from 'pinia'; // import storeToRefs helper hook from pinia
import { useAuthStore } from '~/stores/auth/auth'; // import the auth store

// import { useAuthStore } from '~/store/auth/auth';

definePageMeta({
  // middleware: ["auth"],
  layout: 'landing',
  title: 'My Login Page'
})


// const { authenticateUser } = useAuthStore(); // use authenticateUser action from  auth store
const { authenticated } = storeToRefs(useAuthStore()); // make authenticated state reactive with storeToRefs

const userLoginCredential = ref({
  username: '', 
  password: '',
});
// const email = ref('');
// const password = ref('');

const router = useRouter();
const route = useRoute();
const toastService = usePVToastService();
console.log("1 route.query.error", route.query.error);

onMounted(() => {
  console.log("route.query.error", route.query.error);
  
  const errorMessage = ref(route.query.error);
  if (errorMessage.value) {
    toastService.add({severity:'error', summary: 'Error', detail: errorMessage,  group: 'br', life: 5000});
  }else{
    console.log("no error")

  }
});




const login = async () => {
  const authStore = useAuthStore()
  await authStore.authenticateUser(userLoginCredential.value); // call authenticateUser and pass the user object
  // redirect to homepage if user is authenticated
  if (authenticated.value) {
    console.log("has been authenticated and redirecting to members page");
    await navigateTo('/members');
  }else{
    console.log("not authenticated")
  }
};

</script>


<template >
  <form @submit.prevent="login">
    <div   class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="flex flex-column align-items-center justify-content-center">
            <img src="/img/logo.png" alt="Sakai logo" class="mb-5 w-6rem flex-shrink-0" />
            <div style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)">
                <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius: 53px">
                    <div class="text-center mb-5">
                        <div class="text-900 text-3xl font-medium mb-3">Welcome, Summerherb!</div>
                        <span class="text-600 font-medium">Sign in to continue</span>
                    </div>

                    <div>
                        <label for="username" class="block text-900 text-xl font-medium mb-2">Username</label>
                        <InputText id="username" type="text" placeholder="username" class="w-full md:w-30rem mb-5" style="padding: 1rem" v-model="userLoginCredential.username" autofocus required />

                        <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>
                        <Password id="password1" v-model="userLoginCredential.password" placeholder="Password" :toggleMask="true" class="w-full mb-3" inputClass="w-full" :inputStyle="{ padding: '1rem' }" required></Password>

                        <div class="flex align-items-center justify-content-between mb-5 gap-5">
                            
                            
                        </div>
                        <Button label="Sign In" class="w-full p-3 text-xl" type="submit"></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </form>
</template>
